<template>
    <b-modal
        id="modal-transmision"
        ref="my-modal"
        :title="tituloModal"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- titulo -->
                <b-col cols="12">
                    <b-form-group
                        v-if="tituloModal=='Agregar Transmision'"
                        label-for="Codigo iframe"
                    >
                        <label >Codigo iframe <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="Codigo iframe"
                        rules="required"
                        >
                        <b-form-textarea
                            id="textarea-default"
                            v-model="iframe"
                            placeholder="Inserte aqui el codigo iframe"
                            rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        label-for="Titulo"
                    >
                        <label >Titulo <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="Titulo"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nombre"
                            v-model="titulo"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.titulo" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        label-for="Url"
                    >
                        <label >Url <span class="text-danger">(*)</span></label>
                        <validation-provider
                        #default="{ errors }"
                        name="URL"
                        rules="required|url"
                        >
                        <b-form-input
                            id="url"
                            disabled
                            v-model="url"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.url" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        label="Reproductor"
                        label-for="Reproductor"
                    >
                        <b-embed 
                            class="border"
                            type="iframe" 
                            :allowfullscreen="true" 
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                            :src="url"
                        />
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox
                            id="formulario"
                            v-model="envivo"
                            value=1
                            unchecked-value=0
                            switch
                            >
                            <b>En Vivo</b>
                        </b-form-checkbox>    
                    </b-form-group>    

                    <b-form-group
                        label=""
                        label-for=""
                        >
                        <div class="text-left">
                            <b-button v-if="!imagen" v-b-modal.modal-seleccionar-imagen variant="flat-primary" size="sm">
                                <feather-icon
                                    icon="ImageIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Elegir Logo (200px x 200px)</span>
                            </b-button>
                            <b-button v-else @click="imagen=''" variant="flat-danger" size="sm">
                                <feather-icon
                                    icon="ImageIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Quitar Logo</span>
                            </b-button>
                        </div>
                            <b-img
                                v-if="imagen"
                                :src="imagen" 
                                center
                                :height="200"
                                :width="200"
                                thumbnail
                                class="rounded"
                                alt="Card image cap"
                            />
                        
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect, BEmbed, BFormTextarea, BImg, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ImagenComponent from '@/componentes/ImagenComponent.vue';

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        BEmbed,
        BFormTextarea,
        BImg,
        ImagenComponent,
        BFormCheckbox,
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        iframe: function (val) { 
            if (val) {
                if (val.split('src=')[1]) {
                    let test = val;
                    let src = test.split('src=')[1].split(/[ >]/)[0]
                    this.url=src.replace(/["']/g, "");
                }else{
                    this.iframe='';
                    this.url='';
                }
            }else{
                this.iframe='';
                this.url='';
            }
        },
        url: function () { 
            this.errores.url=[];
        },
    },
    data () {
        return {
            id:null,
            titulo:'',
            url:'',
            imagen:'',
            iframe:'',
            required,
            submitted: false,
            errores:[],
            tituloModal:'',
            envivo:0,
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.tituloModal=='Agregar Transmision'){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.tituloModal='Agregar Transmision';
                    this.titulo='';
                    this.iframe='';
                    this.url='';
                    this.imagen='';
                    this.envivo=0;
                    break;
                }
                case "actualizar":
                {
                    this.tituloModal='Editar Transmision';
                    this.id=data['id'];
                    this.titulo=data['titulo'];
                    this.url=data['url'];
                    this.imagen=data['logo'];
                    this.envivo=data['envivo'];
                    break;	
                }
            }
        },
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(){
            this.$emit('actualizarLista');
            this.submitted = false;
        },
        registrar(){
            axiosIns.post('/admin/transmisiones',{
                'titulo':this.titulo,
                'url':this.url,
                'logo':this.imagen,
                'envivo':this.envivo,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            axiosIns.put('/admin/transmisiones/'+this.id,{
                'titulo':this.titulo,
                'url':this.url,
                'logo':this.imagen,
                'envivo':this.envivo,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        recibirImagen(imagen){
            this.imagen=imagen.url;
        },
    }
}
</script>