<template>
    <b-card title="Transmisiones">
        <b-row>
            <b-col md="6" class="mb-1">
                <b-button variant="primary" v-b-modal.modal-transmision @click="accionTransmision='registrar'" class="mr-1">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Agregar Trasmision</span>
                </b-button>

                <b-button @click="listar()" variant="secondary" class="btn-icon mr-1" >
                    <feather-icon
                        icon="RotateCcwIcon"
                    />
                </b-button>

            </b-col>
            <b-col
                md="6"
                class="mb-1"
            >
                <b-form-group
                label=""
                label-align-sm="right"
                label-for="filterInput"
                class="mb-0"
                >
                <b-input-group>
                    <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar.."
                    />
                </b-input-group>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-table class="sm" responsive
                    ref="selectableTable"
                    selectable
                    select-mode="single" 
                    hover
                    :small="true"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items" 
                    :fields="fields"
                    @row-selected="onRowSelected"
                    show-empty
                    empty-text="No se encontraron registros coincidentes"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    >
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <i class="feather icon-disc primary" />
                        </template>

                        <template v-else>
                            <i class="feather icon-circle" />
                        </template>
                    </template>

                    <template #cell(titulo)="data">
                        <h5>
                            <feather-icon
                            icon="VideoIcon"
                            size="16"
                            />
                            <strong> {{data.value}}</strong>
                        </h5>
                        <span class="text-primary">
                            <feather-icon
                            icon="LinkIcon"
                            size="16"
                            />
                            {{ data.item.url.substr(0,70)}}...
                        </span>
                    </template>

                    <template #cell(logo)="data">
                        <div class="text-nowrap ">
                            <b-img
                                v-if="data.value"
                                :src="data.value" 
                                center
                                fluid
                                :height="75"
                                :width="75"
                                rounded="circle"
                                class="rounded"
                                alt="Card image cap"
                            />
                         </div>
                    </template>

                    <template #cell(envivo)="data">
                        <b-badge :variant="isenvivo[1][data.value]">
                            {{ isenvivo[0][data.value] }}
                        </b-badge>
                    </template>

                    <template #cell(created_at)="data">
                        <div class="text-nowrap">
                            {{data.value}}
                         </div>
                    </template>

                    <template #cell(published_at)="data">
                        <div class="text-nowrap">
                            {{data.value}}
                         </div>
                    </template>

                    <template #cell(accion)="data">
                        <div class="text-nowrap">
                            <b-button
                                v-b-modal.modal-transmision @click="abriComponente(data.item)" 
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                v-b-tooltip.hover.bottom="'Editar'"
                                class="btn-icon mr-1"
                                size="sm"
                            >
                                <feather-icon icon="EditIcon" />
                            </b-button>

                            <b-button
                                @click="confirmar_eliminar(data.item.id)"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-danger"
                                v-b-tooltip.hover.bottom="'Eliminar'"
                                class="btn-icon"
                                size="sm"
                            >
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </div>
                    </template>

                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                class="my-0"
                />
             </b-col>
        </b-row>
        <Transmision 
        :accion="accionTransmision" 
        :item="itemTransmision" 
        @cerrarComponente="cerrarComponente" 
        @actualizarLista="actualizarLista" >
        </Transmision>
    </b-card>
</template>
<script>
import axiosIns from '@/libs/axios'
import {BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BTable, BInputGroup, BPagination, BEmbed, BTableSimple, BBadge} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import Transmision from '@/views/transmisiones/Transmision.vue';

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BImg, 
    BDropdown, BDropdownItem,
    BListGroup, BListGroupItem,
    Transmision,
    BTable,
    BInputGroup,
    BPagination,
    BEmbed,
    BTableSimple,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data () {
      return {
        required,
        submitted: false,
        errores:[],
        items: [],
        fields: [
            { key: 'titulo', label: 'Transmision', sortable: false },
            { key: 'logo', label: 'Logo', sortable: false },
            { key: 'envivo', label: 'Tipo', sortable: false },
            { key: 'published_at', label: 'Publicado', sortable: false },
            { key: 'created_at', label: '', sortable: false },
            { key: 'user.name', label: 'Autor', sortable: false },
            'accion',
        ],
        selected: [],
        filter: null,
        filterOn: [],
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 1,
        currentPage: 1,
        titulo:'',
        medio_id:null,
        isenvivo: [{
            1: 'En vivo', 0: 'Grabado',
          },
          {
            1: 'light-danger', 0: 'light-primary',
          }],
        id:null,
        itemTransmision:[],
        accionTransmision:'',
      }
  },
  created(){          
      this.listar();
  },
  methods: {
    onRowSelected(items) {
        this.selected = items
    },
    selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
        this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
        this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
        this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },

    listar(){
        var url= '/admin/transmisiones';
        axiosIns.get(url)
        .then(res => {
            this.items=res.data;
        })
        .catch(err =>{
            console.log(err);
        });
    },

    confirmar_eliminar(id) {
        this.$swal({
        title: "Advertencia",
        text: "¿Estás seguro de que deseas remover la Transmision?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        }).then((result) => {
        if (result.value) {
            this.eliminar(id);
        }
        });
    },
    eliminar(id) {
        axiosIns.delete('/admin/transmisiones/'+id)
        .then(res => {
            this.$bvToast.toast('Exito', {
            title: 'Se removio correctamente',
            variant:'success',
            solid: false,
            })
            this.listar();
        }) 
        .catch(err =>{
            console.log(err);
        });
    },
    //componente Transmision
    actualizarLista(){
        this.accionTransmision='';
        this.itemTransmision=[];
        this.listar();
    },
    cerrarComponente(){
        this.accionTransmision='';
        this.itemTransmision=[];
    },
    abriComponente(item){
      this.accionTransmision="actualizar";
      this.itemTransmision=item;
    },
  },
}
</script>
<style lang="scss">
.box-imagen-transmision{
    height: 4rem;
  }
  .box-imagen-transmision img {
      width: 100%;
      height: 100%;
  }
  .box-imagen1-transmision img {
      object-fit: contain;
  }
</style>